import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { ConfigProvider, Drawer } from "antd";
import "intl-tel-input/build/css/intlTelInput.css";
import { Button, message } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";

import { fetchProgram } from "../../redux/slices/AddUser/EnrollUserAction";
import Loading from "../CommonComponent/Loading";
import SideBar from "./SideBar";
// import CreatedSlots from "./CreatedSlots";




// implemented lazy loading 
const CreatedSlots = lazy(() => import("./CreatedSlots"));



const AvailSlot = () => {
  const [loading, setLoading] = useState(false);

  const [session, setSession] = useState([]);
  const [sessionSelected, setSessionSelected] = useState("");
  const [programLevel, setProgramLevel] = useState("");
  const [selectedProgramLevel, setSelectedProgramLevel] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourse] = useState("");

  const [semester, setSemester] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState("");

  const [programCourses, setProgramCourses] = useState([]);

  const [subjectsOptions, setSubjectsOptions] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEndTime, setSelectedEndTime] = useState("12:00");

  const [selectedTime, setSelectedTime] = useState("12:00");

  const [slots, setSlots] = useState([]); // Initialize with an empty array
  const [slotIndex, setSlotIndex] = useState(1);
  const [subjectSlotIndexes, setSubjectSlotIndexes] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [subjectId, setSubjectId] = useState(0);
  const [backendResponse, setBackendResponse] = useState(null);

  const [electivesOptions, setElectivesOptions] = useState([]);
  const [electivesListData, setElectivesListData] = useState([]);
  const [electiveSlotIndexes, setElectiveSlotIndexes] = useState({});
  const [allElectives, setAllElectives] = useState([]);

  const [selectedDateForElective, setSelectedDateForElective] = useState(
    new Date()
  );
  const [selectedEndTimeForElec, setSelectedEndTimeForElec] = useState("12:00");
  const [selectedTimeForElec, setSelectedTimeForElec] = useState("12:00");

  // drawer

  const [drawerOpen, setOpenDrawer] = useState(false);

  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;

  useEffect(() => {
    // fetch(`https://apipostman.onlinecu.in/exam_sch/api/sessions_list/`)
    fetch(`https://apipostman.onlinecu.in/exam_sch/api/sessions_list/`,{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
   
      .then((response) => response?.json())
      .then((data) => {
        setSession(data);
      
      })

      .catch((error) => {
        console.error("Error fetching data user role: ", error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await fetchProgram();
      
        const updatedData = apiResponse?.data;
        setProgramLevel(updatedData);
       

        if (apiResponse?.status === 200) {
          setProgramLevel(updatedData);
        }
      } catch (error) {
        console.log(error, "fetchData");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // fetch(`https://apipostman.onlinecu.in/exam_sch/api/semester_list/`)
    fetch(`https://apipostman.onlinecu.in/exam_sch/api/semester_list/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSemester(data);
       
      })
      .catch((error) => {
        console.log(error, "semester_list");
      });
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      if (selectedProgramLevel !== "") {
        try {
          const response = await fetch(
            // `https://apipostman.onlinecu.in/exam_sch/api/program-search/?prog_level_id=${selectedProgramLevel}`
            `https://apipostman.onlinecu.in/exam_sch/api/program-search/?prog_level_id=${selectedProgramLevel}, `,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();
        

          setProgramCourses(data);
          setCourses(data);
        } catch (error) {
          console.error("fetchCourses ", error);
        }
      }
    };

    fetchCourses();
  }, [selectedProgramLevel]);

  useEffect(() => {
    const fetchSubjects = async () => {
      if (selectedCourses !== "" && selectedSemester !== "") {
        try {
          const response = await fetch(
            // `https://apipostman.onlinecu.in/exam_sch/api/subject-search/?program_id=${selectedCourses}&semester_id=${selectedSemester}`
            `https://apipostman.onlinecu.in/exam_sch/api/subject-search/?program_id=${selectedCourses}&semester_id=${selectedSemester}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();

       

          if (Array.isArray(data.subjects)) {
            const subjectsOptions = data.subjects.map((subject) => ({
              label: subject.subject_name,
              value: subject.subject_id,
              code: subject.subject_code,
            }));

          

            setSubjectsOptions(subjectsOptions);
          } else {
            console.error("Invalid data format for subjects");
          }
        } catch (error) {
          console.error("Error fetching subjects: ", error);
        }
      }
    };

    fetchSubjects();
  }, [selectedCourses, selectedSemester]);

  useEffect(() => {
    const fetchElecSubjects = async () => {
      if (selectedCourses !== "" && selectedSemester !== "") {
        try {
          const response = await fetch(
            // `https://apipostman.onlinecu.in/exam_sch/api/electives-search/?program_id=${selectedCourses}&semester_id=${selectedSemester}`
            `https://apipostman.onlinecu.in/exam_sch/api/electives-search/?program_id=${selectedCourses}&semester_id=${selectedSemester}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();

        

          if (Array.isArray(data.electives)) {
            const electivesOptions = data.electives.map((elective) => ({
              label: elective.category,
              value: elective.elective_id,
              electivesList: elective.electiveList,
            }));

           

            setElectivesOptions(electivesOptions);
          } else {
            console.error("Invalid data format for electives");
          }
        } catch (error) {
          console.error("Error fetching electives: ", error);
        }
      }
    };

    fetchElecSubjects();
  }, [selectedCourses, selectedSemester]);

  const handleEnroll = () => {
    // console.log("Selected Subjects:", selectedSubjects);
    // console.log("Selected Start Time:", selectedTime);
    // console.log("Selected End Time:", selectedEndTime);
  };

  const handleAddSlot = (subject) => {
    const currentSlotIndex = subjectSlotIndexes[subject.value] || 0;

    const isDuplicateSlot = slots.some(
      (slot) =>
        slot.subjectId === subject.value &&
        slot.date === selectedDate &&
        ((slot.startTime <= selectedTime && selectedTime < slot.endTime) ||
          (slot.startTime < selectedEndTime &&
            selectedEndTime <= slot.endTime) ||
          (selectedTime <= slot.startTime && slot.endTime <= selectedEndTime))
    );

    if (isDuplicateSlot) {
      messageApi.warning("Same time slot already exists for this subject.");
      return;
    }

    setSubjectSlotIndexes((prevIndexes) => ({
      ...prevIndexes,
      [subject.value]: currentSlotIndex + 1,
    }));

    const newSlot = {
      id: Date.now(),
      subjectId: subject.value,
      date: selectedDate,
      startTime: selectedTime,
      endTime: selectedEndTime,
    };

    setSlots((prevSlots) => [...prevSlots, newSlot]);
    setSubjectId(subject.value); // Update subjectId when adding a new slot
  };

  const handleDeleteLastSlot = (subjectId) => {
    if (slots.length === 0) {
      messageApi.warning("No slots to delete.");
      return;
    }

    // Find the index of the last slot for the specified subject
    const lastIndex = slots
      .slice()
      .reverse()
      .findIndex((slot) => slot.subjectId === subjectId);

    if (lastIndex === -1) {
      messageApi.warning(`No slots found for subject with ID ${subjectId}`);
      return;
    }

    // Calculate the original index in the array
    const originalIndex = slots.length - 1 - lastIndex;

    const deletedSlot = slots[originalIndex];
    const updatedSlots = [
      ...slots.slice(0, originalIndex),
      ...slots.slice(originalIndex + 1),
    ];

    setSlots(updatedSlots);

    messageApi.success(
      `Deleted slot with ID ${deletedSlot.id} for subject with ID ${subjectId}`
    );
  };

  const handleSendData = async () => {
    try {
      if (slots.length === 0) {
        messageApi.warning("No slots to send.");
        return;
      }

      const subjectSlots = slots.filter((slot) => slot.subjectId === subjectId);

      if (subjectSlots.length === 0) {
        messageApi.warning(`No slots found for subject with ID ${subjectId}`);
        return;
      }

      const formattedSlots = subjectSlots.map((slot) => {
        const formattedSlot = `${slot.date} ${slot.startTime} ${slot.endTime},`;
        return formattedSlot.trim();
      });

      const sendData = {
        slot_list: formattedSlots.join(" "), // Join the formatted slots into a space-separated string
        session: parseInt(sessionSelected, 10),
        subject: subjectId,
      };

      const response = await fetch(
        // "https://apipostman.onlinecu.in/exam_sch/api/slots_list/",
        "https://apipostman.onlinecu.in/exam_sch/api/slots_list/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(sendData),
        }
      );

      const responseData = await response.json();
  

      if (responseData?.slot_id) {
        swal({
          title: "Slot Created",
          text: `Slot Created for ${subjectId}`,
          icon: "success",
          button: "Close",
        });
      } else {
        swal({
          title: "Something went wrong",
          text: `Error creating slot for ${subjectId}`,
          icon: "warning",
          button: "Close",
        });
      }

      setBackendResponse(responseData);
    } catch (error) {
      console.error("Error sending data to the backend: ", error);
    }
  };

  const handleSendElectiveData = async (electiveId) => {
    try {
      if (slots.length === 0) {
        messageApi.warning("No slots to send.");
        return;
      }

      const electiveSlots = slots.filter(
        (slot) => slot.subjectId === electiveId
      );

      if (electiveSlots.length === 0) {
        messageApi.warning(`No slots found for elective with ID ${electiveId}`);
        return;
      }

      const formattedSlots = electiveSlots.map((slot) => {
        const formattedSlot = `${slot.date} ${slot.startTime} ${slot.endTime},`;
        return formattedSlot.trim();
      });

      const sendData = {
        slot_list: formattedSlots.join(" "),
        session: parseInt(sessionSelected, 10),
        subject: electiveId,
      };

      const response = await fetch(
        // "https://apipostman.onlinecu.in/exam_sch/api/slots_list/",
        "https://apipostman.onlinecu.in/exam_sch/api/slots_list/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(sendData),
        }
      );

      const responseData = await response.json();
   

      if (responseData?.slot_id) {
        swal({
          title: "Slot Created",
          text: `Slot Created for ${electiveId}`,
          icon: "success",
          button: "Close",
        });
      } else {
        swal({
          title: "Something went wrong",
          text: `Error creating slot for ${electiveId}`,
          icon: "warning",
          button: "Close",
        });
      }

      setBackendResponse(responseData);
    } catch (error) {
      console.error("Error sending data to the backend for electives: ", error);
    }
  };

  const handleSlotChange = (index, field, value) => {
    const updatedSlots = [...slots];
    updatedSlots[index] = {
      ...updatedSlots[index],
      [field]: value,
    };
    setSlots(updatedSlots);
  };

  const handleAddElectiveSlot = (electiveItem) => {
    const isDuplicateSlot = slots.some(
      (slot) =>
        slot.subjectId === electiveItem.elective_id &&
        slot.date === selectedDate &&
        slot.startTime === selectedTime &&
        slot.endTime === selectedEndTime
    );

    if (isDuplicateSlot) {
      messageApi.warning("Same time slot already exists for this elective.");
      return;
    }

    setElectiveSlotIndexes((prevIndexes) => ({
      ...prevIndexes,
      [electiveItem.elective_id]:
        (prevIndexes[electiveItem.elective_id] || 0) + 1,
    }));

    const newSlot = {
      id: Date.now(),
      subjectId: electiveItem.elective_id,
      date: selectedDate,
      startTime: selectedTime,
      endTime: selectedEndTime,
    };

    setSlots((prevSlots) => [...prevSlots, newSlot]);
    setSubjectId(electiveItem.elective_id);
  };

  const hasExistingSlots = (subjectId) => {
    return slots.some((slot) => slot.subjectId === subjectId);
  };

  const handleDeleteLastElectiveSlot = (electiveId) => {
    if (slots.length === 0) {
      messageApi.warning("No slots to delete.");
      return;
    }

    const lastIndex = slots
      .slice()
      .reverse()
      .findIndex((slot) => slot.subjectId === electiveId);

    if (lastIndex === -1) {
      messageApi.warning(`No slots found for elective with ID ${electiveId}`);
      return;
    }

    const originalIndex = slots.length - 1 - lastIndex;

    const deletedSlot = slots[originalIndex];
    const updatedSlots = [
      ...slots.slice(0, originalIndex),
      ...slots.slice(originalIndex + 1),
    ];

    setSlots(updatedSlots);

    messageApi.success(
      `Deleted slot with ID ${deletedSlot.id} for elective with ID ${electiveId}`
    );
  };

  // open drawer

  const openDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  // show slots booked table here

  return (
    <>
      <SideBar />
      {loading ? (
        <Loading />
      ) : (
        <>
          <ConfigProvider>
            <div className="login-page">
              <div className="login-container">
                <div className="login-page-left">
                  <form onSubmit={handleEnroll}>
                    <div className="wrapper-create-slot">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className="heading"
                      >
                        <div>
                          <h2>Create Slot</h2>
                        </div>
                        <div
                          style={{ cursor: "pointer", fontWeight: "bold" }}
                          onClick={openDrawer}
                        >
                          Created Slots
                        </div>
                      </div>

                      <div className="section1">
                      <div className="flex-container">
                        <div className="user-ipt">
                          <label htmlFor="session">Session:</label>
                          <select
                            className="input-field"
                            id="session"
                            value={sessionSelected}
                            onChange={(e) => setSessionSelected(e.target.value)}
                            required
                          >
                            <option key="defaulr" value={0}>
                              {" "}
                              Select Session{" "}
                            </option>
                            {session.map((sessionOption) => (
                              <option
                                key={sessionOption.session_code}
                                value={sessionOption.session_code}
                              >
                                {`${sessionOption.start_month}/${sessionOption.start_year}`}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="user-ipt">
                          <label htmlFor="programLevel">Program Level:</label>

                          <select
                            className="input-field"
                            required
                            id="programLevel"
                            value={selectedProgramLevel}
                            onChange={(e) =>
                              setSelectedProgramLevel(e.target.value)
                            }
                          >
                            <option key="defaulr" value={0}>
                              {" "}
                              Select a program{" "}
                            </option>
                            {Array.isArray(programLevel) ? (
                              programLevel.map((option) => {
                                return (
                                  <>
                                    <option
                                      key={option.prog_level_id}
                                      value={option.prog_level_id}
                                    >
                                      {option.prog_level_name}
                                    </option>
                                  </>
                                );
                              })
                            ) : (
                              <option key="default" value="default">
                                No roles available
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="flex-container">
                        <div className="user-ipt">
                          <label htmlFor="courses">Courses:</label>
                          <select
                            className="input-field"
                            required
                            id="courses"
                            value={selectedCourses}
                            onChange={(e) => setSelectedCourse(e.target.value)}
                          >
                            <option key="defaulr" value="default">
                              {" "}
                              Select Courses
                            </option>
                            {Array.isArray(programCourses) ? (
                              programCourses.map((option) => (
                                <option
                                  key={option.program_id}
                                  value={option.program_id}
                                >
                                  {option.program_name}
                                </option>
                              ))
                            ) : (
                              <option>No Course</option>
                            )}
                          </select>
                        </div>

                        <div className="user-ipt">
                          <label htmlFor="semester">Semester:</label>
                          <select
                            className="input-field"
                            required
                            id="semester"
                            value={selectedSemester}
                            onChange={(e) =>
                              setSelectedSemester(e.target.value)
                            }
                          >
                            <option key="defaulr" value={0}>
                              {" "}
                              Select Semester{" "}
                            </option>
                            {Array.isArray(semester) ? (
                              semester.map((option) => {
                                return (
                                  <>
                                    <option
                                      key={option?.semester_id}
                                      value={option?.semester_id}
                                    >
                                      {option?.semester_name}
                                    </option>
                                  </>
                                );
                              })
                            ) : (
                              <option>No Data</option>
                            )}
                          </select>
                        </div>
                      </div>
                      </div>

                    
                    

                      <div>
                        <label htmlFor="subjects">Subjects:</label>

                        <table>
                          <thead>
                            <tr>
                              <th>Subject Code</th>
                              <th>Subject Name</th>
                              <th>Slots</th>
                            </tr>
                          </thead>

                          <tbody>
                            {subjectsOptions.map((subject) => (
                              <tr
                                key={subject.value}
                                className={
                                  hasExistingSlots(subject.value)
                                    ? "green-background"
                                    : ""
                                }
                              >
                                <td>{subject.code}</td>
                                <td>{subject.label}</td>
                                <td>
                                  <div className="slot-container">
                                    {slots.map((slot, index) =>
                                      slot.subjectId === subject.value ? (
                                        <div
                                          key={slot.id}
                                          className="slot-inputs"
                                        >
                                          <div>
                                            <label>Date:</label>
                                            <input
                                              type="date"
                                              placeholder="Date"
                                              value={slot.date}
                                              onChange={(e) =>
                                                handleSlotChange(
                                                  index,
                                                  "date",
                                                  e.target.value
                                                )
                                              }
                                              style={{ width: "100%" }}
                                            />
                                          </div>
                                          <div>
                                            <label>Start Time:</label>
                                            <input
                                              type="time"
                                              placeholder="Start Time"
                                              value={slot.startTime}
                                              onChange={(e) =>
                                                handleSlotChange(
                                                  index,
                                                  "startTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{ width: "100%" }}
                                            />
                                          </div>
                                          <div>
                                            <label>End Time:</label>
                                            <input
                                              type="time"
                                              placeholder="End Time"
                                              value={slot.endTime}
                                              onChange={(e) =>
                                                handleSlotChange(
                                                  index,
                                                  "endTime",
                                                  e.target.value
                                                )
                                              }
                                              style={{ width: "100%" }}
                                            />
                                          </div>
                                        </div>
                                      ) : null
                                    )}

                                    <div className="slot-buttons">
                                      <button
                                        type="button"
                                        onClick={() => handleAddSlot(subject)}
                                      >
                                        Add Slot{" "}
                                        {subjectSlotIndexes[subject.value] || 1}
                                      </button>

                                      <Button
                                        type="primary"
                                        onClick={handleSendData}
                                      >
                                        Send
                                      </Button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <hr
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          marginTop: "10px",
                          height: "3px",
                          borderRadius: "2px",
                          background: "black",
                        }}
                      />

                      {/* table for electives or specializations are here  */}
                      <div>
                        <label htmlFor="electives">Electives:</label>

                        <table>
                          <thead>
                            <tr>
                              <th>Elective Code</th>
                              <th>Elective Name</th>
                              <th>Elective Slots</th>
                            </tr>
                          </thead>

                          <tbody>
                            {electivesOptions.map((elective) =>
                              // Iterate over electivesList to display each slot for the corresponding elective_code
                              elective.electivesList.map((electiveItem) => (
                                <tr key={electiveItem.elective_id}>
                                  <td>{electiveItem.elective_code}</td>
                                  <td>{electiveItem.elective_name}</td>
                                  <td>
                                    <div className="slot-container">
                                      {slots
                                        .filter(
                                          (slot) =>
                                            slot.subjectId ===
                                            electiveItem.elective_id
                                        )
                                        .map((slot, index) => (
                                          <div
                                            key={slot.id}
                                            className="slot-inputs"
                                          >
                                            <div>
                                              <label>Date:</label>
                                              <input
                                                type="date"
                                                placeholder="Date"
                                                value={
                                                  selectedDateForElective
                                                    .toISOString()
                                                    .split("T")[0]
                                                }
                                                onChange={(e) =>
                                                  setSelectedDateForElective(
                                                    new Date(e.target.value)
                                                  )
                                                }
                                                style={{ width: "100%" }}
                                              />
                                            </div>
                                            <div>
                                              <label>Start Time:</label>
                                              <input
                                                type="time"
                                                placeholder="Start Time"
                                                value={selectedTimeForElec}
                                                onChange={(e) =>
                                                  setSelectedTimeForElec(
                                                    e.target.value
                                                  )
                                                }
                                                style={{ width: "100%" }}
                                              />
                                            </div>
                                            <div>
                                              <label>End Time:</label>
                                              <input
                                                type="time"
                                                placeholder="End Time"
                                                value={selectedEndTimeForElec}
                                                onChange={(e) =>
                                                  setSelectedEndTimeForElec(
                                                    e.target.value
                                                  )
                                                }
                                                style={{ width: "100%" }}
                                              />
                                            </div>
                                          </div>
                                        ))}

                                      <div className="slot-buttons">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleAddElectiveSlot(electiveItem)
                                          }
                                        >
                                          Add Slot{" "}
                                          {electiveSlotIndexes[
                                            electiveItem.elective_id
                                          ] || 1}
                                        </button>
                                        <Button
                                          type="danger"
                                          onClick={() =>
                                            handleDeleteLastElectiveSlot(
                                              electiveItem.elective_id
                                            )
                                          }
                                        >
                                          Delete Last Slot
                                        </Button>
                                        <Button
                                          type="primary"
                                          onClick={() =>
                                            handleSendElectiveData(
                                              electiveItem.elective_id
                                            )
                                          }
                                        >
                                          Send
                                        </Button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/*  drawer component */}
            <Drawer
              title="Slot List"
              placement="right"
              onClose={onCloseDrawer}
              open={drawerOpen}
              width={700}
            >
              <Suspense>
                <CreatedSlots />
              </Suspense>
            </Drawer>
          </ConfigProvider>
        </>
      )}
    </>
  );
};

export default AvailSlot;
