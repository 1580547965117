import axios from "axios";

export const postSession = async (startYear, endYear, startMonth,endMonth,) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let sessionData = {
        start_year: parseInt(startYear),
        end_year: parseInt(endYear),
        start_month: startMonth,
        end_month: endMonth,
    };

    let config = {
      method: "post",
      // url: "https://apipostman.onlinecu.in/exam_sch/api/sessions_list/",
      url: "https://apipostman.onlinecu.in/exam_sch/api/sessions_list/",
      data: sessionData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const apiResponse = await axios(config);

    if (apiResponse?.status === 201) {
      
        swal({
          title: "Session Added",
          text: ``,
          icon: "success",
          button: "Close",
        });
      } else {
      }
    } catch (error) {
    
      swal({
        title: `${error?.code}`,
        text:  `${error?.message}`,
        icon: "warning",
        button: "Close",
      });
    }
  };
  