import axios from "axios";
import swal from "sweetalert";

export const postSemester = async (semesterID, semesterName) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let userData = {
      semester_id: parseInt(semesterID),
      semester_name: semesterName,
    };
    let config = {
      method: "post",
      // url: "https://apipostman.onlinecu.in/exam_sch/api/semester_list/",
      url: "https://apipostman.onlinecu.in/exam_sch/api/semester_list/",
      data: userData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    
    const apiResponse = await axios(config);
   

    if (apiResponse?.status === 201) {
   
      swal({
        title: "Semester Added!",
        text: ``,
        icon: "success",
        button: "Close",
      });
    } else {
    }
  } catch (error) {
   
    swal({
      title: `${error?.code}`,
      text: `${error?.response?.data?.semester_name}`,
      icon: "warning",
      button: "Close",
    });
  }
};
