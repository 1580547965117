import React, { useEffect, useState } from "react";
import adminPro from "../assets/images/adminPro.png";
import "./AdminProfile.css";
import { Button } from "antd";
import { Link } from "react-router-dom";

const AdminProfile = () => {
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState("");

  useEffect(() => {
    const storedAdminData = localStorage.getItem("adminData");
    if (storedAdminData) {
      const parseAdminData = JSON.parse(storedAdminData.trim());
      setAdminData(parseAdminData);
    }
    setLoading(true);
  }, []);

  const getUser = JSON.parse(localStorage.getItem("adminData"));

  return (
    <>
      <div className="body12">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
            position: "absolute",
            marginTop: "100px",
            width: "80vw",
          }}
          className="body-crum"
        >
          <div class="breadcrumb flat">
            <Link to="/email">Home</Link>
            <a href="#" class="active">
              Profile
            </a>
          </div>
        </div>
        <div className="card-profile">
          <div style={{ marginTop: "163px" }}>
            <div className="header-profile">
              <img src={adminPro} />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2 style={{marginTop:"-40px"}}>{getUser?.user_name}</h2>
              {/* <p>
                <span className="user-pro">User Email : </span>{" "}
                <span className="user-det">{getUser?.user_email}</span>
              </p> */}
              <p>
                <span className="user-pro">User ID : </span>{" "}
                <span className="user-det">{getUser?.user_id}</span>
              </p>
              <p>
                <span className="user-pro">Role : </span>
                <span className="user-det">
                  {getUser?.user_role_id === 2 ? "Co-Admin" : "Admin"}
                </span>
              </p>
              <p>
                <span className="user-pro">Access : </span>{" "}
                <span className="user-det">
                  {getUser?.user_role_id === 2
                    ? "Bulk Mail Service"
                    : "All Services"}
                </span>
              </p>
              {getUser?.user_role_id === 1 ? (
                <Button>Edit</Button>
              ) : (
                <Button disabled>Edit</Button>
              )}
            </div>
          </div>
        </div>
        <div className="timer"></div>
      </div>
    </>
  );
};

export default AdminProfile;
