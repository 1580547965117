import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useDropzone } from "react-dropzone";
import axios from "axios";

const OpenModalForBulkStudents = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  //   Upload Function

  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    setUploadedFile(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      axios.defaults.withCredentials = true;
      const response = await axios({
        method: 'post',
        // url:"https://apipostman.onlinecu.in/exam_sch/student_bulk_upload/",
        url:"https://apipostman.onlinecu.in/exam_sch/student_bulk_upload/",
       headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
        // form-data; name="file"; filename="2023 part4.xlsx"
        "Content-Disposition": "attachment; filename=" + uploadedFile.name,
        // "Content-Disposition": 'form-data; name="file"; filename="2023 part4.xlsx"',
        Accept:'*/*'
      }, 
        data:formData
      });
        
       

      setLoading(false);
      swal({
        title: `Students Uploaded successfully`,
        text: ` response.data`,
        icon: "success",
        button: "Close",
      });
     
      // Handle success (e.g., show a success message)
    } catch (error) {
      swal({
        title: `Students upload failed`,
        // text: `${error?.response?.data?.errors[0]?.errors?.user_email}` || `${error?.response?.data?.errors}`,
        text: `Request Failed!!`,
        icon: "error",
        button: "Close",
      });
      console.error("Upload failed:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <button
        style={{
          width: "200px",
          height: "30px",
          fontWeight: "bold",
          fontSize: "14px",
          background: "white",
          color: "black",
        }}
        onClick={showModal}
      >
        Upload Excel File
      </button>
      <Modal
        title="Upload CSV or xlsx"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <div className="bulkupload-modal">
          <div>
            <h2>Upload Students Excel File...</h2>
            <div
              className="bulkupload-page-modal"
              {...getRootProps()}
              style={dropzoneStyles}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the file here...</p>
              ) : (
                <p>Drag & drop an Excel file here, or click to select one</p>
              )}
            </div>
          </div>

          {uploadedFile && (
            <div className="fileUpload" style={{ marginTop: "50px" }}>
              <p>File uploaded: {uploadedFile.name}</p>
              <button onClick={handleUpload}>Upload</button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginTop: "20px",
};

export default OpenModalForBulkStudents;
