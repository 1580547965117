import {
  Table,
  Col,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Checkbox,
  Select,
  notification,
  Progress,
} from "antd";
import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Spinner from "../CommonComponent/Spinner";
import {
  deleteStudent,
  updateStudent,
} from "../../redux/slices/AddUser/EnrollUserAction";
import swal from "sweetalert";
import { sendDynamicEmails } from "../../redux/slices/smtpStudents/smptForStudents";
import { MailOutlined } from "@ant-design/icons";
import gmailIcon from "../assets/images/gmailIcon.png";
import deleteicon from "../assets/images/deleteicon.png";
import editgig from "../assets/images/editgig.gif";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
// import excel from "../../components/assets/images/excel.png"
import excel from "../assets/images/excel.png";
import { Link } from "react-router-dom";
// import * as FileSaver from "file-saver";

// /home/ubuntu/frontend_exam_slot/src/components/assets/images/gmailIcon.png
// frontend_exam_slot/src/components/assets/images/gmailIcon.png

const SendEmailModal = React.memo(
  ({
    visible,
    selectedUsers,
    onCancel,
    onOk,
    emailForm,
    modalEmailMessage,
    setModalEmailMessage,
    getUserDataById,
    removeUserFromSelection,
    progress,
  }) => (
    <Modal
      title={`Sending emails to the following ${selectedUsers.length} user:`}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form form={emailForm}>
        <div>
          <p>Selected Users:</p>
          <ul className="userEmailList">
            {selectedUsers.map((userId) => (
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  width: "auto",
                  marginRight: "auto",
                }}
                key={userId}
              >
                <span>{getUserDataById(userId)?.student_email}</span>
                <Button
                  type="link"
                  className="remove-email"
                  onClick={() => removeUserFromSelection(userId)}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <p>Email Subject:</p>
          <Form.Item name="emailSubject">
            <Input
              value={modalEmailMessage.subject}
              onChange={(e) =>
                setModalEmailMessage({
                  ...modalEmailMessage,
                  subject: e.target.value,
                })
              }
            />
          </Form.Item>
        </div>
        <div>
          <p>Email Message:</p>
          <Form.Item name="emailMessage">
            <Input.TextArea
              rows={4}
              value={modalEmailMessage.body}
              onChange={(e) =>
                setModalEmailMessage({
                  ...modalEmailMessage,
                  body: e.target.value,
                })
              }
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
);

const EnrolledStudentList = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [emailForm] = Form.useForm();
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { Option } = Select;
  const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false);
  const [filteredUserIds, setFilteredUserIds] = useState([]);
  const [modalEmailMessage, setModalEmailMessage] = useState({
    subject: "",
    body: "",
  });
  const [selectedProgramLevel, setSelectedProgramLevel] = useState(1); // Initialize with a default value
  const [programData, setProgramData] = useState([]);

  const [progress, setProgress] = useState(10);

  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;

  const handleEdit = (record) => {
    setVisible(true);
    setSelectedUser(record);
    form.setFieldsValue({
      student_name: record.student_name,
      student_email: record.student_email,
      student_uid: record.student_uid,
      student_mobile: record.student_mobile,
      prog_level_name: record.prog_level_name,
      program_name: record.program_name,
      gender: record.gender,
      subjects: record.subjects,
      semester_name: record.semester_name,
    });
  };

  const handleProgramLevelChange = (value) => {
    setSelectedProgramLevel(value);
  };

  const getUser = JSON.parse(localStorage.getItem("adminData"));

  const handleDelete = (record) => {
    const student_id = record.student_id;
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this student?",
      onOk() {
        deleteStudent(student_id)
          .then(() => {
            // Remove the deleted user from local state
            setStudentList((prevUserList) =>
              prevUserList.filter(
                (user) => user.student_id !== record.student_id
              )
            );

            // Fetch the updated list of users from the server
            fetchData();

            swal({
              title: "Student Deleted!",
              text: ``,
              icon: "success",
              button: "Close",
            });
          })
          .catch((error) => {
            swal({
              title: "Error!",
              text: ``,
              icon: "warning",
              button: "Close",
            });
          });
      },
    });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedUser = { ...selectedUser, ...values };

      const apiResponse = await updateStudent(
        updatedUser.student_id,
        updatedUser
      );

     

      setStudentList((prevUserList) =>
        prevUserList.map((user) =>
          user.student_id === updatedUser.student_id ? updatedUser : user
        )
      );

      setVisible(false);
    } catch (error) {
      console.log("Error updating user:", error);
      // Handle error here (e.g., show notification or log further details)
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://apipostman.onlinecu.in/exam_sch/api/program-search/?prog_level_id=${selectedProgramLevel}`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        // `https://apipostman.onlinecu.in/exam_sch/api/program-search/?prog_level_id=${selectedProgramLevel}`
      );
      const data = await response.json();
    

      setProgramData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedProgramLevel]);

  useEffect(() => {
    const fetchUserList = async () => {
      setLoading(true);
      try {
        let allResults = [];
        let nextPage = 1;
        while (true) {
          const response = await fetch(
            // `https://apipostman.onlinecu.in/exam_sch/api/studentenrollment_list/?prog_level_id=${selectedProgramLevel}&page=${nextPage}`
            `https://apipostman.onlinecu.in/exam_sch/api/studentenrollment_list/?prog_level_id=${selectedProgramLevel}&page=${nextPage}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const data = await response.json();
          const results = data?.results || [];
       
          allResults = [...allResults, ...results];
          if (!data?.next) {
            break; // No more pages
          }
          nextPage++;
        }
        const sortedData = allResults.sort(
          (a, b) => b.student_id - a.student_id
        );
        setStudentList(sortedData);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserList();
  }, [selectedProgramLevel]);

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) =>
        index + 1 + (record.current === 1 ? 0 : totalRecords),
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      key: "student_name",
    },
    {
      title: "Email",
      dataIndex: "student_email",
      key: "student_email",
    },
    {
      title: "Session",
      dataIndex: "session",
      key: "session",
    },
    {
      title: "Semester",
      dataIndex: "semester_name",
      key: "semester_name",
    },
    {
      title: "Program",
      dataIndex: "program_name",
      key: "program_name",
    },
    {
      title: "Program Level",
      dataIndex: "prog_level_name",
      key: "prog_level_name",
    },

    {
      title: "UID",
      dataIndex: "student_uid",
      key: "student_uid",
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space
          style={{ display: "flex", flexDirection: "column" }}
          size="middle"
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {getUser?.user_role_id === 2 ? (
              <p>Not Allowed</p>
            ) : (
              <>
                {" "}
                <button
                  style={{
                    width: "100px",
                    backgroundColor: "rgb(64 105 79)",
                    marginRight: "10px",
                  }}
                  type="primary"
                  onClick={() => handleEdit(record)}
                >
                  Edit{" "}
                  <span style={{ marginLeft: "10px" }}>
                    <img
                      style={{
                        width: "20px",
                        marginTop: "3px",
                        borderRadius: "50%",
                      }}
                      src={editgig}
                    />
                  </span>
                </button>
              </>
            )}
            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <button
                  style={{ width: "100px" }}
                  type="primary"
                  danger
                  onClick={() => handleDelete(record)}
                >
                  Delete{" "}
                  <span style={{ marginLeft: "10px" }}>
                    <img
                      style={{ width: "20px", marginTop: "3px" }}
                      src={deleteicon}
                    />
                  </span>
                </button>
              </>
            )}
          </div>

          {selectedUsers.includes(record.student_id) && (
            <button
              style={{ width: "120px", background: "#E5E637", color: "black" }}
              type="primary"
              onClick={() => handleSendEmail([record.student_id])}
            >
              Send Email{" "}
              <span style={{ marginLeft: "10px" }}>
                <img
                  style={{ width: "20px", marginTop: "3px" }}
                  src={gmailIcon}
                />
              </span>
            </button>
          )}
        </Space>
      ),
    },
    {
      title: "Select",
      key: "select",
      render: (text, record) => (
        <Checkbox
          onChange={(e) => handleUserSelect(e, record)}
          checked={selectedUsers.includes(record.student_id)}
        />
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {};

  const handleSendEmail = (selectedUsers) => {
    setSendEmailModalVisible(true);
   
  };

  const handleSelectAll = () => {
    const allUserIds =
      filteredUserIds.length > 0
        ? filteredUserIds
        : studentList.map((user) => user.student_id);
    setSelectedUsers(allUserIds);
  };
  const handleUserSelect = (e, record) => {
    const selectedId = record.student_id;

    if (e.target.checked) {
      setSelectedUsers((prevSelected) => [...prevSelected, selectedId]);
    } else {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((id) => id !== selectedId)
      );
    }
  };

  const handleDeselectAll = () => {
    setSelectedUsers([]);
  };

  const handleSendEmailOk = async () => {
    let totalUsers = 0;
    let successfulEmails = 0;
    let failedEmails = 0;

    try {
      setSendEmailModalVisible(true);
      setProgress(0);
      setLoading(true);

      const values = await emailForm.validateFields();

      totalUsers = selectedUsers.length;

      const usersToUpdate = await Promise.all(
        // allSelectedUsers.map(async (userId) => {
        selectedUsers.map(async (userId) => {
          const user = getUserDataById(userId);
          const emailSentSuccessfully = await sendDynamicEmails(
            [user.student_email],
            values.emailSubject,
            values.emailMessage
          );

          if (emailSentSuccessfully) {
            successfulEmails += 1;
          } else {
            failedEmails += 1;
          }

          return {
            ...user,
            emailStatus: emailSentSuccessfully ? "sent" : "failed",
          };
        })
      );

      setStudentList((prevUserList) =>
        prevUserList.map(
          (user) =>
            usersToUpdate.find(
              (updatedUser) => updatedUser.student_id === user.student_id
            ) || user
        )
      );

      setSendEmailModalVisible(false);
      emailForm.resetFields();
      setModalEmailMessage({ subject: "", body: "" });

    
      swal({
        title: `Total Users: ${totalUsers} || Successful Emails: ${successfulEmails}`,
        text: `Progress: ${((successfulEmails / totalUsers) * 100).toFixed(
          2
        )}%`,
        icon: "success",
        button: "Close",
      });
     
    
    } catch (error) {
      console.error("Error sending email:", error);

      swal({
        title: `Total Users: ${totalUsers} || Failed Emails: ${failedEmails}`,
        text: `Progress: ${((successfulEmails / totalUsers) * 100).toFixed(
          2
        )}%`,
        icon: "warning",
        button: "Close",
      });

      // Show the list of users with failed emails
      // You can display it in a modal or another suitable way
      console.log("Users with failed emails:", selectedUsers);
    } finally {
      setLoading(false);
      setProgress(100);
    }
  };

  const handleSendEmailCancel = () => {
    setSendEmailModalVisible(false);
    emailForm.resetFields();
    setModalEmailMessage("");
  };

  const getUserDataById = (userId) => {
    return studentList.find((user) => user.student_id === userId);
  };

  const removeUserFromSelection = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.filter((id) => id !== userId)
    );
  };

  const filteredStudentList = studentList.filter((item) => {
    const lowerSearchText = searchText.toLowerCase();
    return (
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(lowerSearchText)
      ) ||
      (typeof item.program_level === "string" &&
        item.program_level.toLowerCase().includes(lowerSearchText))
    );
  });

  const handleSearch = (value) => {
    setSearchText(value);

    const filteredIds = studentList
      .filter((item) => {
        const lowerSearchText = value.toLowerCase();
        return (
          Object.values(item).some(
            (val) =>
              typeof val === "string" &&
              val.toLowerCase().includes(lowerSearchText)
          ) ||
          (typeof item.program_level === "string" &&
            item.program_level.toLowerCase().includes(lowerSearchText))
        );
      })
      .map((user) => user.student_id);

    setFilteredUserIds(filteredIds);
  };

  const handleDownloadExcel = () => {
    const dataToExport = searchText ? filteredStudentList : studentList;

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "StudentList");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `EnrolledStudentList${searchText ? "_searched" : ""}.xlsx`);

    notification.success({
      message: "Download Success",
      description: "Excel File for Student Table Downloaded",
    });
  };

  return (
    <>
      <SideBar />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="contain-table">
            <div style={{ marginTop: "130px" }} className="table-list">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h2>Enrolled Student List</h2>
                </div>
                <div>
                  <button onClick={handleDownloadExcel}>
                    Download Excel{" "}
                    <img
                      style={{ width: "30px", marginLeft: "10px" }}
                      src={excel}
                    />
                  </button>
                </div>
              </div>

              <Col>
                <Link to="/Anonymous">
                  <button
                    style={{
                      width: "400px",
                      background: "#E5E637",
                      color: "black",
                    }}
                    type="primary"
                  >
                    Swift Mail Dispatcher{" "}
                    <span style={{ marginLeft: "10px" }}>
                      <img
                        style={{ width: "20px", marginTop: "3px" }}
                        src={gmailIcon}
                      />
                    </span>
                  </button>
                </Link>
              </Col>
              <Col>
                <div className="flex-container-row">
                  <Input.Search
                    className="search-field"
                    placeholder="Search by Mail, Name, UID"
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ marginBottom: 16, fontWeight: "bold" }}
                  />

                  <div className="flex-container-rowBox">
                    <Button
                      style={{
                        width: "100px",
                        marginRight: "10px",
                        backgroundColor: "#680000",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={handleSelectAll}
                    >
                      Select All
                    </Button>
                    <Button
                      style={{ width: "100px" }}
                      onClick={handleDeselectAll}
                    >
                      Deselect All
                    </Button>
                  </div>
                </div>

                <SendEmailModal
                  visible={sendEmailModalVisible}
                  selectedUsers={selectedUsers}
                  onCancel={() => setSendEmailModalVisible(false)}
                  onOk={handleSendEmailOk}
                  emailForm={emailForm}
                  modalEmailMessage={modalEmailMessage}
                  setModalEmailMessage={setModalEmailMessage}
                  getUserDataById={getUserDataById}
                  removeUserFromSelection={removeUserFromSelection}
                />

                <Table
                  dataSource={filteredStudentList}
                  columns={columns}
                  pagination={{
                    pageSize: 100,
                    showTotal: (total, range) =>
                      `${range[0] + totalRecords}-${
                        range[1] + totalRecords
                      } of ${total + totalRecords} items`,
                    onChange: handlePageChange,
                  }}
                />
                <Modal
                  title="Edit User"
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Form form={form} layout="vertical">
                    <Form.Item name="student_name" label="Student Name">
                      <Input />
                    </Form.Item>
                    <Form.Item name="student_email" label="Email">
                      <Input />
                    </Form.Item>
                    <Form.Item name="student_uid" label="UID">
                      <Input />
                    </Form.Item>
                    <Form.Item name="session" label="Session">
                      <Input />
                    </Form.Item>
                    <Form.Item name="student_mobile" label="Mobile">
                      <Input />
                    </Form.Item>
                    <Form.Item name="prog_level_name" label="Level">
                      <Input />
                    </Form.Item>
                    <Form.Item name="semester_name" label="Semester">
                      <Input />
                    </Form.Item>
                    <Form.Item name="program_name" label="Program">
                      <Input />
                    </Form.Item>
                  </Form>
                </Modal>
              </Col>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EnrolledStudentList;
