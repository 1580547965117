import {
  ControlOutlined,
  HomeOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SideBar = () => {
  const [isActive, setIsActive] = useState(false);
  const [adminData, setAdminData] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [showAddProgram, setShowAddProgram] = useState(false);

  useEffect(() => {
    const storedAdminData = localStorage.getItem("adminData");
    if (storedAdminData) {
      const parseAdminData = JSON.parse(storedAdminData.trim());
      setAdminData(parseAdminData);
    }
  }, []);

  useEffect(() => {
    const storeLastLogin = localStorage.getItem("lastLogin");
  
  }, []);

  const handleToggleClick = () => {
    setIsActive(!isActive);
  };

  const clearStorage = () => {
    localStorage.clear();
    window.location.reload();
    window.location.href = "/login";
  };

  const handleProgramSettingHover = () => {
    setShowAddProgram(true);
  };

  const handleProgramSettingLeave = () => {
    setShowAddProgram(false);
  };

  const getUser = JSON.parse(localStorage.getItem("adminData"));

  return (
    <div>
      <nav style={{ marginTop: "110px" }} className={isActive ? "active" : ""}>
        <div>
          <header>
            <h2>Dashboard</h2>
            <a href="/">
              <UnorderedListOutlined />
            </a>
          </header>
          <ul>
            <button
              style={{ width: "100%" }}
              className="toggle"
              onClick={handleToggleClick}
            >
              <UnorderedListOutlined />
            </button>

           
              {getUser?.user_role_id === 2 ? null : <>  <li>
                  <a className="toggle">
                    <Link to="/">
                      <span className="icon">
                        <HomeOutlined />
                      </span>
                      <span className="title">Dashboard</span>
                    </Link>
                  </a>
                </li></>}

            <li>
              <a className="toggle">
                <Link to="/email">
                  <span className="icon">
                  <MailOutlined />
                  </span>
                  <span className="title">Email</span>
                </Link>
              </a>
            </li>

            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <li>
                  <a className="toggle">
                    <Link to="/create-slot">
                      <span className="icon">
                        <SettingOutlined />
                      </span>
                      <span className="title">Create Slot</span>
                    </Link>
                  </a>
                </li>
              </>
            )}

            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <li>
                  <a className="toggle">
                    <Link to="/menu">
                      <span className="icon">
                        <SettingOutlined />
                      </span>
                      <span className="title">Exam Settings</span>
                    </Link>
                  </a>
                </li>
              </>
            )}

            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <li>
                  <a className="toggle">
                    <Link to="/enroll_student">
                      <span className="icon">
                        <UsergroupAddOutlined />
                      </span>
                      <span className="title">Enroll Student</span>
                    </Link>
                  </a>
                </li>
              </>
            )}

            {/*             
            <li
              onMouseEnter={handleProgramSettingHover}
              onMouseLeave={handleProgramSettingLeave}
              className={showAddProgram ? "highlighted" : ""}
            >
              <a className="toggle">
                <span className="icon">
                  <ControlOutlined />
                </span>
                <span className="title">Program Setting</span>
                {showAddProgram && (
                  <div className="dropdown-sidebar">
                    <Link to="/add-program">
                      <span className="title">Add Program</span>
                    </Link>
                  </div>
                )}
              </a>
            </li> */}

            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <li>
                  <a className="toggle">
                    <Link to="/add-user">
                      <span className="icon">
                        <UsergroupAddOutlined />
                      </span>
                      <span className="title">Enroll User</span>
                    </Link>
                  </a>
                </li>
              </>
            )}

            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <li>
                  <a className="toggle">
                    <Link to="/user_table">
                      <span className="icon">
                        <ControlOutlined />
                      </span>
                      <span className="title">List of Users</span>
                    </Link>
                  </a>
                </li>
              </>
            )}

            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <li>
                  <a className="toggle">
                    <Link to="/enrolled_students">
                      <span className="icon">
                        <ControlOutlined />
                      </span>
                      <span className="title">List of Students</span>
                    </Link>
                  </a>
                </li>
              </>
            )}

            {/* 
            <li>
              <a  className="toggle">
                <Link to="/enroll_student">
                <span className="icon">
                  <UsergroupAddOutlined />
                </span>
                <span className="title">Student Enroll</span>
                </Link>
               
              </a>
            </li> */}

            {getUser?.user_role_id === 2 ? null : (
              <>
                {" "}
                <li className="bottom">
                  <a onClick={clearStorage} className="toggle">
                    <span className="icon">
                      <LogoutOutlined />
                    </span>
                    <span className="title">Logout</span>
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default SideBar;
