import React from 'react'

const SlotBookForm = () => {
  return (
    <div className='container-student'>
      <h1>Book Slot Form </h1>
    </div>
  )
}

export default SlotBookForm
