import axios from "axios";

export const postGender = async (genderName) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let userGender = {
      gender_name: genderName,
      gender_status: "active"
    };

    let config = {
      method: "post",
      // url: "https://apipostman.onlinecu.in/exam_sch/api/genders_create/",
      url: "https://apipostman.onlinecu.in/exam_sch/api/genders_create/",
      data: userGender,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const apiResponse = await axios(config);
    if (apiResponse?.status === 201) {
     
      swal({
        title: "Session Added",
        text: ``,
        icon: "success",
        button: "Close",
      });
    } else {
    }
  } catch (error) {
   
    swal({
      title: `${error?.code}`,
      text: `${error?.message}`,
      icon: "warning",
      button: "Close",
    });
  }
};
