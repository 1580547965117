


import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js';

const PiechartSession2024 = () => {
  const [data, setData] = useState([]);

  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;

  useEffect(() => {
    // Fetch data from the API
    // fetch('https://apipostman.onlinecu.in/exam_sch/api/enrollment_count/')
    fetch('https://apipostman.onlinecu.in/exam_sch/api/enrollment_count/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Filter data for the two sessions
  const sessionData202307 = data.filter(entry => entry.session === '202307');
  const sessionData202401 = data.filter(entry => entry.session === '202401');

  // Extract program and count for each session
  const labels202307 = sessionData202307.map(entry => entry.program);
  const data202307 = sessionData202307.map(entry => entry.count);

  const labels202401 = sessionData202401.map(entry => entry.program);
  const data202401 = sessionData202401.map(entry => entry.count);

  const chartData202307 = {
    labels: labels202307,
    datasets: [
      {
        data: data202307,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FBA834', '#416D19', '#EBF400', '#F72798'], 
      },
    ],
  };

  const chartData202401 = {
    labels: labels202401,
    datasets: [
      {
        data: data202401,
        backgroundColor: ['#FF6384','#EBF400', '#36A2EB', '#FFCE56', "#68000", '#416D19', '#F72798'], 
      },
    ],
  };

  const options = {
    devicePixelRatio: 2, 
  };

  return (
    


      <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}} >
        <h2 style={{display:"flex", fontWeight:"bold", fontSize:"20px", width:"45vw", justifyContent:"center", fontFamily:" Poppins, sans-serif"}}>Students registered for the program in session 202401.</h2>
        <Pie data={chartData202401} options={options} />
      </div>
    
  );
};

export default PiechartSession2024;
