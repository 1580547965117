import React, { useEffect, useState } from "react";
import { Drawer, Radio, Space, message, notification } from "antd";
import { ConfigProvider, Card } from "antd";
import { useDispatch } from "react-redux";

// import longlogo2 from "../../assets/images/longlogo.png";
import longlogo2 from "../../assets/images/postbox1.png";
import { LoginManual } from "../../../redux/slices/authentication/authSliceAction";
import Spinner from "../Spinner";
import { SmtpAction } from "../../../redux/slices/ForgetPassword/smtpAction";

const LoginPage = () => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [user_email, setUserEmail] = useState("");
  const [user_password, setPassword] = useState("");
  const [userNameValidation, setUserNameValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);

  const [to, setTo] = useState("");

  const token = JSON.parse(localStorage.getItem("slotbook"));

  const accessToken = token?.accessToken;

  useEffect(() => {
    const isLoggedIn = true;

    if (isLoggedIn) {
      notification.success({
        message: "Logout Successful",
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      await LoginManual(user_email, user_password);
      sessionStorage.setItem(
        "loginNotification",
        JSON.stringify({
          message: "Login Successful",
          description: "Welcome back!",
        })
      );

      const currentTimestamp = new Date().toISOString();
      localStorage.setItem("lastLogin", currentTimestamp);
    } finally {
      setLoading(false);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await SmtpAction({ to: to.toLowerCase() });
      // alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Error sending email. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Space>
            <Radio.Group value={placement} onChange={onChange}></Radio.Group>
          </Space>
          <Drawer
            title="Reset Password"
            placement={placement}
            width={500}
            onClose={onClose}
            open={open}
          >
            <div className="reset">
              <form onSubmit={handleResetSubmit}>
                <div className="reset-form">
                  <h3>
                    After clicking the reset button, a newly generated password
                    will be sent to your email. Kindly ensure to check your spam
                    folder as well. Once you receive the password, you can use
                    it to log in here.
                  </h3>
                </div>
                <div className="reset-form">
                  <h3 htmlFor="studentName">User Email</h3>
                  <input
                    type="email"
                    id="userEmail"
                    className="input-field-reset"
                    placeholder="User Email"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    // required
                  />
                </div>
                <div className="reset-form">
                  <button style={{ width: "400px" }}>
                    Reset Password <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </form>
            </div>
          </Drawer>
          <ConfigProvider>
            <div style={{ display: "flex" }} className="body-new">
              <form style={{ display: "flex" }} onSubmit={handleSubmit}>
                <div className="logo-login11">
                  <img
                    style={{
                      width: "21vw",
                      height: "55vh",
                      marginRight: "10px",
                    }}
                    src={longlogo2}
                  />
                </div>
                <div className="wrapper-new">
                  <div className="heading-new">
                    <div>
                      <h2
                        style={{ letterSpacing: "1.4px", fontWeight: "bold" }}
                      >
                        Login
                      </h2>
                    </div>
                  </div>

                  <div className="input-group-new">
                    <input
                      type="text"
                      id="username"
                      className="input-field-new"
                      placeholder="Email"
                      required
                      value={user_email}
                      onChange={(e) => setUserEmail(e.target.value)}
                      onMouseDown={(e) => setUserNameValidation(true)}
                    />
                  </div>
                  {user_email.length === 0 && userNameValidation ? (
                    <p
                      style={{
                        color: "red",
                        marginTop: "-10px",
                        padding: "0px",
                      }}
                    >
                      User Name is Required{" "}
                    </p>
                  ) : (
                    ""
                  )}

                  <div className="input-group-new">
                    <input
                      type="password"
                      id="password"
                      className="input-field-new"
                      placeholder="Password"
                      required
                      value={user_password}
                      onChange={(e) => setPassword(e.target.value)}
                      onMouseDown={(e) => setPasswordValidation(true)}
                      maxLength={12}
                    />
                  </div>

                  {user_password.length === 0 && passwordValidation ? (
                    <p
                      style={{
                        color: "red",
                        padding: "0px",
                        marginTop: "-10px",
                      }}
                    >
                      Password is Required{" "}
                    </p>
                  ) : (
                    " "
                  )}

                  <div className="input-group-new row">
                    <div
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        letterSpacing: "1px",
                      }}
                      onClick={showDrawer}
                      className="row-new"
                    >
                      Forgot password?
                    </div>
                  </div>

                  <div className="input-group-new">
                    <button className="button-new">
                      Login <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </form>
              <footer
                style={{
                  color: "black",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                }}
              >
                || Bulk Mail Service ||
              </footer>
            </div>
          </ConfigProvider>
        </>
      )}
    </>
  );
};

export default LoginPage;
