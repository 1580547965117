import swal from "sweetalert";

export const sendDynamicEmails = async (receiverEmails, subject, body) => {
  const token = JSON.parse(localStorage.getItem("slotbook"));
 
  const accessToken = token?.accessToken;
  try {
    let data = {
      // receiver_emails: receiverEmails,
      // emails: receiverEmails.map((email) => ({ receiver_email: email })),
      emails: receiverEmails.map((email) => email),
      subject: subject,
      body: body,
    };

    const response = await fetch(
      // "https://apipostman.onlinecu.in/exam_sch/api/send-email/",
      "https://apipostman.onlinecu.in/exam_sch/api/send-email/",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },

        body: JSON.stringify(data),
      }
    );

  

    if (response.ok || response.status === 200) {
      // swal({
      //   title: "Email sent successfully!!",
      //   text: "Email Sent",
      //   icon: "success",
      //   button: "Close",
      // });
      const successData = await response.json();
     
      swal({
        title: ` ${successData?.message}  `,
        text: `Total Email ${successData?.total_emails_sent} || Success Emails ${successData?.successful_emails} || Failed Emails ${successData?.failed_emails}`,
        icon: "success",
        button: "Close",
      });

      return true;
    } else {
      const errorData = await response.json();
      console.log(errorData, "ERROR DATA");

      // swal({
      //   title: errorData?.message || "Error sending email",
      //   text: errorData?.failed_emails[0]?.error,
      //   icon: "warning",
      //   button: "Close",
      // });

      swal({
        title: successData?.message || "Error sending emails",
        text: `Total Email sent ${successData?.total_emails_sent} || Success Email ${successData?.successful_emails} || Email Failed ${successData?.failed_emails?.length}`,
        icon: "warning",
        button: "Close",
      });

      return false;
    }
  } catch (error) {
    console.error("Error sending email:", error);
    swal({
      title: "Error sending email",
      text: error?.message,
      icon: "error",
      button: "Close",
    });

    return false;
  }
};

// import swal from "sweetalert";

// export const sendDynamicEmails = async (receiverEmails, subject, body) => {
//   try {
//     let data = {
//       emails: receiverEmails.map(email => ({ receiver_email: email })),
//       subject: subject,
//       body: body,
//     };

//     const response = await fetch(
//       // "https://apipostman.onlinecu.in/exam_sch/api/send-email/",
//       "https://apipostman.onlinecu.in/exam_sch/api/send-email/",
//       {
//         method: "post",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }
//     );

//     console.log(response, "RERSRSR");

//     if (response.ok || response.status === 200) {
//       swal({
//         title: "Emails sent successfully!!",
//         text: "Emails Sent",
//         icon: "success",
//         button: "Close",
//       });

//       return true;
//     } else {
//       const errorData = await response.json();
//       console.log(errorData, "ERROR DATA");

//       swal({
//         title: errorData?.message || "Error sending emails",
//         text: errorData?.failed_emails[0]?.error,
//         icon: "warning",
//         button: "Close",
//       });

//       return false;
//     }
//   } catch (error) {
//     console.error("Error sending emails:", error);
//     swal({
//       title: "Error sending emails",
//       text: error?.message,
//       icon: "error",
//       button: "Close",
//     });

//     return false;
//   }
// };

// ...
