import { useState } from "react";
import swal from "sweetalert";

export const sendDynamicEmailsSwift = async (receiverEmails, subject, body,) => {



  const token = JSON.parse(localStorage.getItem("slotbook"));

  const getUserId = token?.userId;

 
  const accessToken = token?.accessToken;

  const formData = new FormData();
  formData.append("user_id", getUserId)
  try {
    let data = {
      receiver_emails: receiverEmails.map((email) => email),
      subject: subject,
      body: body,

    };

    const response = await fetch(
      // "https://apipostman.onlinecu.in/exam_sch/api/send-email/",
      // "https://apipostman.onlinecu.in/exam_sch/api/send-email/",
      `https://apipostman.onlinecu.in/exam_sch/api/send-email/?user_id=${getUserId}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }
    );

 

    if (response.ok || response.status === 200) {
      const resultCount = await response.json();
      // console.log(resultCount, "EMAIL RESPONSE");
    
      swal({
        title: "Emails sent successfully!!",
        // text: `Total Emails ${resultCount?.total_emails_sent} || Success Emails ${resultCount?.successful_emails} || Failed Emails ${resultCount?.failed_emails}`,
        icon: "success",
        button: "Close",
      });

      return true;
    } else {
      const errorData = await response.json();
     

      swal({
        title: errorData?.message || "Error sending emails",
        // text: `Total Email sent ${errorData?.total_emails_sent} || Success Email ${errorData?.successful_emails} || Email Failed ${errorData?.failed_emails}`,
        icon: "warning",
        button: "Close",
      });

      return false;
    }
  } catch (error) {
    console.error("Error sending emails:", error);
    swal({
      title: "Error sending emails",
      text: error?.message,
      icon: "error",
      button: "Close",
    });

    return false;
  }
};
